import request from "@/request";
import { ResultCommon, pointsMallType } from "@/types/common";
import { GetMallListData, convertData, convert } from "@/types/mall";

export const getMallList = (data: GetMallListData): Promise<pointsMallType> => {
  return request.post<pointsMallType>({
    url: "/v-product/score/goods/pages",
    data,
  });
};

export const convertScore = (data: convert): Promise<pointsMallType> => {
  return request.post<pointsMallType>({
    url: "/v-product/score/order/change",
    data,
    headers: {
      unErrorMsg: true,
    },
  });
};
